import { Controller } from 'stimulus'
import { GoogleMapsLoader } from 'frontend-helpers'

const DEFAULT_POS = { lat: 24, lng: -104 }
const DEFAULT_ZOOM = 5

export default class LocationsMapController extends Controller {
  static targets = ['container', 'address', 'restaurant', 'franchisee']

  async connect () {
    this.openInfoWindow = null

    try {
      this.googleMaps = await GoogleMapsLoader({
        key: this.data.get('key'),
        libraries: ['drawing', 'places']
      })
      this.initializeMaps()
      this.initializeInput()
      this.geolocalize()
      this.addMarkers()
    } catch (error) {
      console.error(error)
    }
  }

  initializeMaps () {
    this.map = new this.googleMaps.Map(this.containerTarget, {
      center: DEFAULT_POS,
      zoom: DEFAULT_ZOOM,
      backgroundColor: '#0a0a0a',
      styles: [
        { elementType: 'geometry', stylers: [{ color: '#454545' }] },
        {
          featureType: 'administrative',
          elementType: 'labels',
          stylers: [{ color: '#292929' }]
        },
        {
          featureType: 'administrative',
          elementType: 'labels.text.stroke',
          stylers: [{ color: '#C0C0C0' }]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [{ color: '#C0C0C0' }]
        },
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }]
        },
        {
          featureType: 'landscape',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }]
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{ color: '#0a0a0a' }]
        }
      ]
    })
  }

  resetMapPosition () {
    this.map.setCenter(DEFAULT_POS)
    this.map.setZoom(DEFAULT_ZOOM)
    this.addressTarget.value = ''
  }

  initializeInput () {
    // Create the autocomplete object, restricting the search predictions to
    // geographical location types.
    this.autocomplete = new this.googleMaps.places.Autocomplete(
      this.addressTarget,
      {
        componentRestrictions: { country: 'mx' },
        types: ['geocode']
      }
    )

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    this.autocomplete.setFields([
      'geometry',
      'address_components',
      'place_id',
      'name'
    ])

    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    this.autocomplete.addListener(
      'place_changed',
      this.centerMapLocation.bind(this)
    )
  }

  geolocalize () {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        this.map.setCenter(pos)
        this.map.setZoom(12)
      })
    }
  }

  centerMapLocation () {
    const place = this.autocomplete.getPlace()
    const pos = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng()
    }

    if (place.address_components.length == 1) {
      this.map.setZoom(5)
    } else if (place.address_components.length == 2) {
      this.map.setZoom(7)
    } else if (place.address_components.length == 3) {
      this.map.setZoom(12)
    } else if (place.address_components.length == 4) {
      this.map.setZoom(14)
    } else if (place.address_components.length > 4) {
      this.map.setZoom(15)
    }

    this.map.setCenter(pos)
  }

  addMarkers () {
    const restaurants = this.restaurantTargets.map(target => {
      const data = target.dataset
      return {
        name: data.name,
        address: data.address,
        state: data.state,
        lat: parseFloat(data.lat),
        lng: parseFloat(data.lng),
        mapsPlaceId: data.mapsPlaceId,
        franchisee: data.franchisee,
        url: data.url
      }
    })

    const franchisees = this.franchiseeTargets.reduce((accumulator, target) => {
      accumulator[target.dataset.name] = {
        name: target.dataset.name,
        facturacion: target.dataset.facturacion,
        phone: target.dataset.phone
      }

      return accumulator
    }, {})

    restaurants.forEach(restaurant => {
      const franchisee = franchisees[restaurant.franchisee]
      this.generateMarker(restaurant, franchisee)
    })
  }

  generateMarker = (restaurant, franchisee) => {
    const phone = restaurant.phone || franchisee.phone

    const contentString = `
      <div class="info-window">
        <h5 class="title is-5">${restaurant.name}</h5>
        <p class="subtitle is-5">${restaurant.address}</p>

        <h6 class="title is-6">Teléfono</h6>
        <p class="subtitle is-6">${phone}</p>

        <a href="${restaurant.url}" class="button is-red is-small is-bold">Ir a Restaurante</a>
      </div>
      `

    const infowindow = new google.maps.InfoWindow({
      content: contentString
    })

    const image =
      'https://carlsjrmx.netlify.app/assets/icons/map-marker-icon-48x48-shadow.png'
    const position = { lat: restaurant.lat, lng: restaurant.lng }

    const starMarker = new google.maps.Marker({
      position: position,
      map: this.map,
      title: restaurant.name,
      icon: image,
      animation: google.maps.Animation.DROP
    })

    starMarker.addListener('click', () => {
      if (this.openInfoWindow) {
        this.openInfoWindow.close()
        this.openInfoWindow = null
      }

      this.map.setCenter(position)

      infowindow.open(this.map, starMarker)
      this.openInfoWindow = infowindow
    })
  }
}
